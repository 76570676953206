import React, { useState } from 'react';

// material
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

// icons
import ApartmentIcon from '@material-ui/icons/Apartment';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Home from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/BuildRounded';
import QueueIcon from '@material-ui/icons/Queue';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
// import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';

import { Badge, Box } from '@material-ui/core';
import { isMobile } from 'utils/functions';
import NotificationMobile from 'commons/components/SideBar/components/Notification';

import type from 'utils/type';
import {
  Assessment,
  EmojiTransportation,
  ListAlt,
  Tune, VideoLibrary,
} from '@material-ui/icons';
import styles from './styles';

const styleDrawer = () => ({
  root: {
    flexGrow: 1,
  },
  paperAnchorLeft: {
    width: 300,
  },
});

const useStylesDrawer = makeStyles(styleDrawer);
const useStyles = makeStyles(styles);
const useStylesList = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(6),
  },
}));

const SideBar = ({
  user,
  isOpen,
  toggleDrawer,
  handleItemClick,
  logOut,
  openHolding,
  isOpenNotification,
  handleOpenNotification,
  handleClearNotification,
  notifications,
  totalNotification,
  executive,
  handleClickNotification,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const classesDrawer = useStylesDrawer();
  const listClasses = useStylesList();
  const userHasReports = user?.reports?.length > 0;

  const rol = user?.user?.type;
  const { name, email, phone } = executive;

  const handleClick = () => {
    setOpen(!open);
  };

  const handleNotification = () => {
    openHolding('openHolding');
  };

  const handleClose = () => {
    toggleDrawer();
    handleClearNotification();
  };

  const views = [
    [
      {
        dataUser: true,
        rolActive: true,
        disabled: false,
        label: user.user.email,
        module: '/account',
        icon: <AccountCircleOutlinedIcon />,
        className: classes.mobileItems,
      },
      {
        dataUser: true,
        rolActive: rol === type.GESTOR_DE_FLOTA_DECISOR || rol === type.ENCARGADO_DE_FLOTA_SECCION,
        disabled: false,
        label: 'Ejecutivo',
        description: [!name ? 'No informado' : name, email, phone],
        module: '/',
        icon: <PhoneAndroidOutlinedIcon />,
        className: classes.mobileItems,
      },
      {
        dataUser: true,
        rolActive: (rol === type.GESTOR_DE_FLOTA_DECISOR
          || rol === type.ADMINISTRADOR_DE_CONTRATO || rol === type.ENCARGADO_DE_FLOTA_SECCION),
        disabled: false,
        label: 'Notificaciones',
        module: '/',
        icon: (
          <Badge badgeContent={totalNotification?.length} color="secondary">
            <NotificationsOutlinedIcon />
          </Badge>
        ),
        className: classes.mobileItems,
        functionClick: handleOpenNotification,
      },
      {
        dataUser: true,
        rolActive: true,
        disabled: false,
        label: 'Holding',
        module: '/',
        icon: <ApartmentIcon />,
        className: classes.mobileItems,
        functionClick: handleNotification,
      },
    ],
    [
      {
        rolActive: true, disabled: false, label: 'Inicio', module: '/', icon: <Home />,
      },
      {
        rolActive: true, disabled: false, label: 'Dashboard', module: '/dashboard', icon: <DashboardIcon />,
      },
      {
        rolActive: rol === type.ADMINISTRADOR_DE_CONTRATO
        || rol === type.ADMINISTRADOR_PORTAL_SALFA
        || userHasReports,
        disabled: !userHasReports,
        label: 'Reportes',
        module: '/reports',
        icon: <AssignmentIcon />,
      },
      {
        rolActive: true, disabled: false, label: 'Vehículos', module: '/vehicles', icon: <DirectionsCarIcon />,
      },
      {
        rolActive: true, disabled: false, label: 'Documentos', module: '/document', icon: <DescriptionIcon />,
      },
      {
        rolActive: true, disabled: false, label: 'Detenciones', module: '/services', icon: <BuildIcon />,
      },
      {
        rolActive: true, disabled: false, label: 'Tipos de detención', module: '/work-orders', icon: <EmojiTransportation />,
      },
      {
        rolActive: true, disabled: false, label: 'Estados de pago', module: '/payment-status', icon: <Assessment />,
      },
      {
        rolActive: true, disabled: false, label: 'Video tutoriales', module: '/video-tutorials', icon: <VideoLibrary />,
      },
      // { label: 'Cobros adicionales', module: '/', icon: <CreditCardIcon /> },
      // { label: '"Comuniación"', module: '/', icon: <ShareIcon /> },
    ],
    // [
    //   { label: 'Contrato', module: '/', icon: <DirectionsCarIcon /> },
    //   { label: 'Cuenta corriente', module: '/', icon: <AccountBalanceWalletOutlinedIcon /> },
    //   { label: 'Estado pago', module: '/', icon: <ReceiptIcon /> },
    // ],
  ];

  const routes = {
    fleets: { label: 'Flota', module: '/fleets', icon: <DirectionsCarIcon /> },
    users: { label: 'Usuarios', module: '/user', icon: <AccountBoxIcon /> },
    requests: { label: 'Solicitudes', module: '/request', icon: <QueueIcon /> },
    system: { label: 'Sistema', module: '/system', icon: <BuildIcon /> },
    paymentStatus: {
      label: 'Configurar estado de pago',
      module: '/payment-status-config',
      icon: <Tune />,
    },
    clientSettings: {
      label: 'Configurar ejecutivo',
      module: '/client-settings',
      icon: <PeopleIcon />,
    },
    paymentStatusSections: {
      label: 'Configurar secciones estado de pago',
      module: '/payment-status-sections',
      icon: <ListAlt />,
    },
    holdingMaintainer: {
      label: 'Holdings',
      module: '/holding-maintainer',
      icon: <BusinessIcon />,
    },
    reportsMaintainer: {
      label: 'Reportes',
      module: '/reports-maintainer',
      icon: <AssignmentIcon />,
    },
  };

  const maintainers = {
    ADMINISTRADOR_PORTAL_SALFA: [
      routes.holdingMaintainer,
      routes.reportsMaintainer,
      routes.fleets,
      routes.users,
      routes.requests,
      routes.system,
      routes.paymentStatus,
    ],
    ADMINISTRADOR_DE_CONTRATO: [
      routes.fleets,
      routes.users,
      routes.requests,
      routes.paymentStatus,
      routes.paymentStatusSections,
    ],
    'GESTOR_DE_FLOTA/DECISOR': [
      routes.users,
      routes.fleets,
      routes.paymentStatus,
      routes.clientSettings,
    ],
    'ENCARGADO_DE_FLOTA/SECCION': [
      routes.paymentStatus,
    ],
  };

  const MenuItem = ({
    functionClick,
    dataUser,
    module,
    label,
    icon,
    description = [],
    className = {},
    rolActive,
    disabled = false,
  }) => (
    <>
      {(dataUser && !isMobile()) ? <div key={label} /> : (
        (disabled || rolActive)
        && (
        <ListItem
          onClick={functionClick || handleItemClick(module)}
          button
          component="div"
          color="primary"
          key={`menu-${label}`}
          className={className.nested}
          disabled={disabled}
        >
          <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>

          <ListItemText
            primary={(<Box className={classes.drawTitle}>{label}</Box>)}
            color="primary"
            secondary={description.length > 0
              ? (
                description.map((desc) => (
                  <React.Fragment key={desc}>
                    {desc}
                    <br />
                  </React.Fragment>
                ))
              ) : null}
          />
        </ListItem>
        )

      )}
    </>
  );

  return (
    <>
      <Drawer
        classes={classesDrawer}
        open={isOpen}
        onClose={toggleDrawer}
      >
        <List>
          { views[0].map((view) => (
            <MenuItem {...view} key={`${view.label}`} />
          )) }
          {isMobile() && <Divider />}
          {views[1].map(({
            module, label, icon, rolActive, disabled,
          }) => (
            <MenuItem
              module={module}
              label={label}
              icon={icon}
              rolActive={rolActive}
              disabled={disabled}
              // key={`${label}-1`}
              key={label}
            />
          ))}
          <Divider />
          {/*         {views[2].map(({ module, label, icon }) => (
          <MenuItem module={module} label={label} icon={icon} />
        ))}
        <Divider /> */}
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <SettingsIcon className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText primary="Configuración" className={classes.primaryItem} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              { maintainers[rol].map(({ module, label, icon }) => (
                <MenuItem
                  module={module}
                  label={label}
                  icon={icon}
                  className={listClasses}
                  rolActive
                  disabled={false}
                  key={`${label}-${module}-${icon}`}
                />
              )) }
            </List>
          </Collapse>
          <Divider />
          <ListItem
            onClick={logOut}
            button
          >
            <ListItemIcon>
              <ExitToAppIcon className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" className={classes.primaryItem} />
          </ListItem>
        </List>
      </Drawer>
      <NotificationMobile
        isOpen={isOpenNotification}
        clear={handleClearNotification}
        notifications={notifications}
        totalNotification={totalNotification}
        handleClick={handleClickNotification}
        handleClose={handleClose}
      />
    </>
  );
};

export default SideBar;
